import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { ClipLoader } from 'react-spinners'

import { Auth, API, Util } from '../amplify'
import * as LEASE_STATUS from '../data/leaseStatus'

import PortalPage from './PortalPage'
import Button from './Button'
import LeaseBar from './LeaseBar'
import SearchBar from './SearchBar'
import BrokerDropdown from './BrokerDropdown'

const LeaseOverview = ({
  user,
  refreshAuthStatus,
  brokerList,
  selectedBrokerFilter,
  setSelectedBrokerFilter,
}) => {
  const IS_ADMIN = user.attributes?.['custom:type'] === 'admin'
  const URL_LEASE_STATUS =
    typeof window !== 'undefined' ? window.location.pathname.split('/')[3] : ''

  // if URL status is invalid
  const leaseStatus = LEASE_STATUS[`LEASE_${URL_LEASE_STATUS}`]
  if (!leaseStatus?.url) {
    navigate('/portal')
  }

  const [data, setData] = useState({
    loading: true,
    leases: [],
    brokers: {},
    nextToken: null,
  })
  const [search, setSearch] = useState(null)

  useEffect(() => {
    fetchLeaseData()
  }, [selectedBrokerFilter])

  const baseQuery = {
    type: 'lease',
    sortDirection: 'DESC',
    limit: 5000,
    nextToken: data.nextToken,
  }
  const baseFilter = {
    status: {
      eq: leaseStatus.status,
    },
  }
  const searchFilter = {
    cased_lessee_name: {
      contains: search?.toUpperCase(),
    },
  }

  const leaseQuery = IS_ADMIN
    ? {
        ...baseQuery,
        filter: {
          ...baseFilter,
          ...(search && searchFilter),
          ...(selectedBrokerFilter[0].value && {
            broker_id: {
              eq: selectedBrokerFilter[0].value,
            },
          }),
        },
      }
    : {
        ...baseQuery,
        filter: {
          ...baseFilter,
          ...(search && searchFilter),
          broker_id: {
            eq: user.username,
          },
        },
      }

  const fetchLeaseData = async () => {
    try {
      const leaseResponse = (await API.listSortedLeases(leaseQuery)).data
        .listLeasesSortedByCreated

      const dedupedUsers = leaseResponse.items.reduce(
        (acc, lease) => ({ ...acc, [lease.broker_id]: 'broker' }),
        {},
      )

      let userResponse = {}
      if (Object.keys(dedupedUsers).length) {
        userResponse = await API.listUsers({
          filter: {
            or: Object.keys(dedupedUsers).map(id => ({
              id: {
                eq: id,
              },
            })),
          },
        })
      }

      const userMap = userResponse?.data?.listUsers?.items?.reduce(
        (acc, userObj) => ({
          ...acc,
          [userObj.id]: userObj,
        }),
        {},
      )

      setData({
        ...data,
        loading: false,
        leases: leaseResponse.items,
        brokers: userMap || {},
        nextToken: leaseResponse.nextToken,
      })
    } catch (error) {
      Util.LogError('ERROR_FETCHING_LEASES', error, {
        user: user.attributes,
      })
    }
  }

  // pagination was never ever used lol
  const fetchMoreLeases = async () => {
    setData({ ...data, loading: true })

    try {
      const leaseResponse = (await API.listSortedLeases(leaseQuery)).data
        .listLeasesSortedByCreated

      setData({
        ...data,
        loading: false,
        leases: [...data.leases, ...leaseResponse.items],
        nextToken: leaseResponse.nextToken,
      })
    } catch (error) {
      Util.LogError('ERROR_FETCHING_MORE_LEASES', error, {
        user: user.attributes,
      })
    }
  }

  const fetchSearchedLeases = async () => {
    setData({ ...data, loading: true, nextToken: null })

    if (!search) {
      setSearch(null)
      await fetchLeaseData()
      return
    }

    try {
      const leaseResponse = (
        await API.listSortedLeases({ ...leaseQuery, nextToken: null })
      ).data.listLeasesSortedByCreated

      setData({
        ...data,
        loading: false,
        leases: leaseResponse.items,
        nextToken: leaseResponse.nextToken,
      })
    } catch (error) {
      Util.LogError('ERROR_FETCHING_SEARCHED_LEASES', error, {
        user: user.attributes,
      })
    }
  }

  const renderLeases = () => {
    return data.leases.map(lease => (
      <LeaseBar
        key={lease.id}
        lease={lease}
        broker={data.brokers[lease.broker_id]}
        fetchLeaseData={fetchLeaseData}
        IS_ADMIN={IS_ADMIN}
      />
    ))
  }

  const onDropdownChange = option => {
    setSelectedBrokerFilter(option)
  }

  return (
    <PortalPage
      user={user}
      headerLinks={[
        <Link className='button-danger' to='/portal/' key='backToPortalLink'>
          Back to Applications
        </Link>,
        <BrokerDropdown
          key='brokerDropdown'
          IS_ADMIN={IS_ADMIN}
          brokerList={brokerList}
          selectedBrokerFilter={selectedBrokerFilter}
          onDropdownChange={onDropdownChange}
        />,
        <Button
          buttonClass='button-danger'
          onClick={async () => {
            await Auth.logout()
            refreshAuthStatus()
          }}
          key='logoutLink'
        >
          Logout
        </Button>,
      ]}
    >
      <div className='dashboard'>
        <div className='dashboard-title'>
          <div>{leaseStatus.pluralDisplay}</div>
          <div>
            <SearchBar
              placeholder='Search By Main Lessee Name'
              handleChange={e => setSearch(e.target.value)}
              fetchSearchedLeases={fetchSearchedLeases}
            />
            <Button onClick={fetchSearchedLeases}>Search</Button>
          </div>
        </div>
        {data.loading ? (
          <div className='col-12 mt-10 d-flex justify-content-center'>
            <ClipLoader loading size='60px' color='#3b9453' />
          </div>
        ) : (
          renderLeases()
        )}

        {/* Pagination */}
        {data.nextToken && (
          <div
            className='pagination'
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <button className='active' onClick={fetchMoreLeases}>
              Show More Leases
            </button>
          </div>
        )}
      </div>
    </PortalPage>
  )
}

LeaseOverview.propTypes = {
  user: PropTypes.object,
  refreshAuthStatus: PropTypes.func,
  brokerList: PropTypes.array,
  selectedBrokerFilter: PropTypes.array,
  setSelectedBrokerFilter: PropTypes.func,
}

export default LeaseOverview
