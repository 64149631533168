import React, { useState } from 'react'
import propTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'
import { PDFDocument } from 'pdf-lib'
import moment from 'moment'

import { API, Storage, Lambda, Util } from '../amplify'
import { renderForm, parseLeaseFiles, parseCoLessees } from '../util'
import { formatNumber, isMobile } from '../util/helpers'
import { createToast } from '../util/reactHelpers'
import * as LEASE_STATUS from '../data/leaseStatus'

import Button from './Button'
import LeaseMainInfo from './LeaseMainInfo'
import LeaseFiles from './LeaseFiles'
import LeaseAdminReview from './LeaseAdminReview'
import LeaseNeedsInfo from './LeaseNeedsInfo'
import LeaseDenied from './LeaseDenied'
import creditApprovalDoc from '../assets/pdf/credit-approval.pdf'
import creditDecisionDoc from '../assets/pdf/credit-decision.pdf'

const LeaseCreditForm = ({
  data,
  user,
  setData,
  onLeaseInputChange,
  IS_ADMIN,
  didSubmitReview,
  setDidSubmitReview,
}) => {
  const DOLLAR_FIELDS = [
    'equipment_cost',
    'administration_fee',
    'collateral_exposure',
    'exist_value',
    'dp_final',
    'trade_u_value',
    'trade_in_value',
    'equipment_value',
    'clbo_value',
    'total_exposure',
    'security_deposit',
    'additional_coll_value',
  ]

  const [needsInfoUpdate, setNeedsInfoUpdate] = useState({
    loading: false,
    response: null,
    successful: false,
  })
  const [adminCreditReview, setAdminCreditReview] = useState({
    status: null,
    feedback: null,
    loading: false,
    response: null,
    successful: false,
  })

  const [creditLogUpdate, setCreditLogUpdate] = useState({
    loading: false,
    response: null,
    successful: false,
    generateApprovalDocResponse: null,
    generateDecisionDocResponse: null,
  })

  const [adminUpdateLoading, setAdminUpdateLoading] = useState(false)

  const IS_MOBILE = isMobile('sm')

  const renderCreditMessage = () => {
    let message

    switch (data.lease.status) {
      case LEASE_STATUS.LEASE_APPROVED_CREDIT.status:
        message = (
          <h5>
            We are pleased to inform you that your credit application has been
            approved.
            <br />
            Please view the &apos;Lease Documentation&apos; tab to complete the
            Contract.
          </h5>
        )
        break

      case LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status:
        message = (
          <h5>
            After submitting the credit application, we have determined there is
            still some information needed.
            <br />
            Please see the feedback.
          </h5>
        )
        break

      case LEASE_STATUS.LEASE_PENDING_CREDIT.status:
        message = (
          <h5>
            Your credit submission is being reviewed. Thank you for your
            patience.
          </h5>
        )
        break

      case LEASE_STATUS.LEASE_DENIED_CREDIT.status:
        message = (
          <h5>
            After submitting the credit application, we have denied the
            application. Please see the feedback.
          </h5>
        )
        break

      default:
        message = <span />
        break
    }

    return message
  }

  const submitAdminReview = async e => {
    e.preventDefault()

    let status
    let notificationStatus
    let ccAddContract = false
    let docURL = null
    const generatedCreditApprovalDoc = data.files.creditLog.reduce(
      (acc, file) => acc || file.key.includes('credit_approval_doc'),
      false,
    )

    switch (adminCreditReview.status) {
      case LEASE_STATUS.APPROVE_CREDIT:
        status = LEASE_STATUS.LEASE_APPROVED_CREDIT.status
        notificationStatus = 'has been credit approved by ADD Capital'
        ccAddContract = true
        if (!generatedCreditApprovalDoc) {
          return createToast(
            'You must generate a Credit Approval Doc before approving!',
            'error',
          )
        }
        docURL = await Storage.getDocumentURL(
          `leases/${data.lease.id}/creditLog/credit_approval_doc.pdf`,
        )
        break

      case LEASE_STATUS.DENY_CREDIT:
        status = LEASE_STATUS.LEASE_DENIED_CREDIT.status
        notificationStatus = 'has been denied credit by ADD Capital'
        break

      case LEASE_STATUS.NEEDS_INFO_CREDIT:
        status = LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status
        notificationStatus = 'needs more credit information'
        break

      default:
        break
    }

    try {
      setAdminCreditReview({
        ...adminCreditReview,
        loading: true,
      })
      await API.updateLease({
        ...data.lease,
        status,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
        admin_credit_feedback: adminCreditReview.feedback,
        admin_review_name: user.attributes.name,
      })

      // send notification to broker
      await Lambda.sendEmail({
        IS_NOTIFICATION: true,
        TO: data.broker.email,
        FROM: 'CREDIT',
        CONTRACT_CC: ccAddContract,
        CREDIT_APPROVAL_DOC_URL: docURL,
        name: data.broker.name,
        clientName: data.lease.main_lessee_name,
        leaseName: data.lease.main_lessee_name,
        leaseStatus: notificationStatus,
        leaseNextSteps:
          'View the Credit Application to see the credit approval details or view the Lease Documentation tab to start filling out lease contract information',
        leaseID: data.lease.id,
      })

      // so admin cannot flip tabs and re-review again
      setDidSubmitReview({
        credit: true,
        contract: false,
      })
      const msg = 'Successfully Reviewed Credit Application'
      createToast(msg)
      setAdminCreditReview({
        ...adminCreditReview,
        loading: false,
        response: msg,
        successful: true,
      })
    } catch (error) {
      Util.LogError('ERROR_ADMIN_CREDIT_RESPONSE', error, {
        user: user.attributes,
        data,
      })
      const msg =
        'An unknown error has occurred when submitting the response. Please try again.'
      createToast(msg, 'error')
      setAdminCreditReview({
        ...adminCreditReview,
        loading: false,
        response: msg,
        successful: false,
      })
    }
  }

  const submitCreditLogUpdate = async e => {
    e.preventDefault()

    setCreditLogUpdate({
      loading: true,
      response: null,
    })

    try {
      await API.updateLease({
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
      })

      const msg = 'Successfully Saved Credit Log'
      createToast(msg)
      setCreditLogUpdate({
        loading: false,
        response: msg,
        successful: true,
      })
    } catch (error) {
      Util.LogError('ERROR_SAVING_CREDIT_LOG', error, {
        user: user.attributes,
        data,
      })
      const msg = 'An unknown error has occurred. Please try again.'
      createToast(msg, 'error')
      setCreditLogUpdate({
        loading: false,
        response: msg,
      })
    }
  }

  // this essentially does the exact same thing as the method above for saving the credit log
  // maybe the credit log should only save those fields, or we can combine these methods
  const updateCredit = async () => {
    setAdminUpdateLoading(true)

    try {
      await API.updateLease({
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
      })
      const msg = 'Successfully Saved Credit Form'
      createToast(msg)
      setAdminUpdateLoading(false)
    } catch (error) {
      Util.LogError('ERROR_UPDATING_CREDIT_FORM', error, {
        user: user.attributes,
        data,
      })
      const msg = 'An unknown error has occurred. Please try again.'
      createToast(msg, 'error')
      setAdminUpdateLoading(false)
    }
  }

  const submitNeedsInfoUpdate = async () => {
    setNeedsInfoUpdate({
      loading: true,
      response: null,
    })

    try {
      await API.updateLease({
        id: data.lease.id,
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
        status: LEASE_STATUS.LEASE_PENDING_CREDIT.status,
      })

      // send notification to ADD about credit resubmission
      await Lambda.sendEmail({
        IS_NOTIFICATION: true,
        TO: 'CREDIT',
        FROM: 'CREDIT',
        name: 'ADD Capital',
        clientName: data.lease.main_lessee_name,
        leaseName: data.lease.main_lessee_name,
        leaseStatus: 'has been re-submitted for credit approval by the broker',
        leaseNextSteps:
          'View the credit application to provide feedback to the broker',
        leaseID: data.lease.id,
      })

      const msg = 'Successfully Re-submitted Credit Application'
      createToast(msg)
      setNeedsInfoUpdate({
        loading: false,
        response: msg,
        successful: true,
      })
    } catch (error) {
      Util.LogError('ERROR_RESUBMITTING_LEASE', error, {
        user: user.attributes,
        data,
      })
      const msg =
        'An unknown error has occurred when re-submitting the credit application. Please try again.'
      createToast(msg, 'error')
      setNeedsInfoUpdate({
        loading: false,
        response: msg,
      })
    }
  }

  const generateCreditDoc = async (blankCreditDoc, docType) => {
    setCreditLogUpdate({
      loading: true,
    })
    try {
      const blankPdfBytes = await fetch(blankCreditDoc).then(res =>
        res.arrayBuffer(),
      )

      const pdfDoc = await PDFDocument.load(blankPdfBytes)

      // Get all the form fields in this document, populate from lease data
      const form = pdfDoc.getForm()
      const fields = form.getFields()
      fields.forEach(field => {
        const name = field.getName()
        if (DOLLAR_FIELDS.includes(name)) {
          field.setText(String(formatNumber(Number(data.lease[name])) || ''))
        } else {
          field.setText(String(data.lease[name] || ''))
        }
      })

      // Fill in broker name
      const brokerNameField = form.getFieldMaybe('broker_name')
      brokerNameField?.setText(String(data.broker.name || ''))

      // Fill in reference number
      const referenceNumber = form.getFieldMaybe('reference_number')
      referenceNumber?.setText(String(data.lease.reference_number || ''))

      // Fill in current date
      const date = moment().format('MMMM DD, YYYY')
      const dateField = form.getTextField('date')
      dateField.setText(date)

      // Fill in colessees
      const coLesseesString = parseCoLessees(data.lease.co_lessees)
      const coLesseesField = form.getFieldMaybe('co_lessee_names')
      coLesseesField.setText(coLesseesString)

      // Fill in non lease data fields
      const net =
        Number(data.lease.equipment_cost) -
        Number(data.lease.dp_final) -
        Number(data.lease.trade_in_value) +
        Number(data.lease.trade_u_value)

      const netField = form.getFieldMaybe('net')
      netField?.setText(String(formatNumber(net) || ''))

      form.flatten()

      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save()

      const blob = new Blob([pdfBytes], { type: 'application/pdf' })

      // Upload file to storage then set updated files locally
      await Storage.uploadDocument(
        `leases/${data.lease.id}/creditLog/${docType}.pdf`,
        blob,
      )
      const updatedFiles = await Storage.listDocuments(
        `leases/${data.lease.id}`,
      )
      const sortedFiles = parseLeaseFiles(updatedFiles)
      setData({
        ...data,
        files: sortedFiles,
      })

      if (docType === 'credit_approval_doc') {
        createToast('Successfully Generated Credit Approval Doc')
        setCreditLogUpdate({
          loading: false,
          generateApprovalDocResponse: 'Successfully Generated',
        })
      } else {
        createToast('Successfully Generated Credit Decision Doc')
        setCreditLogUpdate({
          loading: false,
          generateDecisionDocResponse: 'Successfully Generated',
        })
      }
    } catch (error) {
      Util.LogError('ERROR_GENERATING_CREDIT_DOC', error, {
        user: user.attributes,
        data,
      })
      if (docType === 'credit_approval_doc') {
        const msg = 'Failed to generate credit approval doc. Try again later.'
        createToast(msg, 'error')
        setCreditLogUpdate({
          loading: false,
          generateApprovalDocResponse: msg,
        })
      } else {
        const msg = 'Failed to generate credit decision doc. Try again later.'
        createToast(msg, 'error')
        setCreditLogUpdate({
          loading: false,
          generateDecisionDocResponse: msg,
        })
      }
    }
  }

  const IS_GENERAL_INFO_EDITABLE = IS_ADMIN
    ? true
    : data.lease.status === LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status

  const IS_CREDIT_LOG_EDITABLE = IS_ADMIN
    ? true
    : data.lease.status === LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status ||
      data.lease.status === LEASE_STATUS.LEASE_PENDING_CREDIT.status

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '50px' }}>
        {!IS_ADMIN && renderCreditMessage()}
      </div>

      {/* Main Lease Info */}
      <LeaseMainInfo data={data} />
      <div className='d-flex justify-content-between'>
        <div className='w-100'>
          {/* Lease General Info Form */}
          <div
            className='dropdown-form'
            style={{ width: '100%', marginBottom: 50 }}
          >
            <div className='dropdown-form-title'>
              General Credit Information
            </div>
            <div className='dropdown-form-content'>
              {renderForm({
                schema: 'creditGeneral',
                labelType: 'view',
                IS_ADMIN,
                IS_MOBILE,
                inputProps: {
                  disabled:
                    !IS_GENERAL_INFO_EDITABLE || needsInfoUpdate.successful,
                  onChange: IS_GENERAL_INFO_EDITABLE
                    ? onLeaseInputChange
                    : undefined,
                  data: data.lease,
                },
              })}
              {IS_ADMIN && (
                <div className='text-center'>
                  {adminUpdateLoading ? (
                    <ClipLoader loading size='30px' color='#3b9453' />
                  ) : (
                    <>
                      <div style={{ fontSize: 10, color: 'red' }}>
                        ATTENTION: Any edits made will be saved on the broker
                        side too!
                      </div>
                      <Button onClick={updateCredit}>Save Credit Form</Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        {IS_ADMIN ? (
          <div className='d-flex w-100'>
            {/* Lease Credit Log */}
            <div
              className='dropdown-form'
              style={{ width: '100%', marginBottom: 50 }}
            >
              <div className='dropdown-form-title'>Credit Log Information</div>
              <div className='dropdown-form-content'>
                <form onSubmit={submitCreditLogUpdate}>
                  {renderForm({
                    schema: 'creditLog',
                    labelType: 'edit',
                    IS_ADMIN,
                    IS_MOBILE,
                    inputProps: {
                      disabled: !IS_CREDIT_LOG_EDITABLE,
                      onChange: IS_CREDIT_LOG_EDITABLE
                        ? onLeaseInputChange
                        : undefined,
                      data: data.lease,
                    },
                  })}
                  <div className='text-center'>
                    {/* Only show save button when form is editable and not loading */}
                    {creditLogUpdate.loading ? (
                      <ClipLoader loading size='35px' color='#3b9453' />
                    ) : (
                      IS_CREDIT_LOG_EDITABLE && (
                        <>
                          <div className='text-center p-1'>
                            <Button type='submit'>Save Credit Log</Button>
                          </div>
                          <div className='lease-generate-docs-container'>
                            <div>
                              <div style={{ fontSize: 10, color: 'red' }}>
                                ATTENTION: Generated approval file will be
                                visible to the broker upon credit approval
                              </div>
                              <Button
                                onClick={() =>
                                  generateCreditDoc(
                                    creditApprovalDoc,
                                    'credit_approval_doc',
                                  )
                                }
                              >
                                Generate Credit Approval Doc
                              </Button>
                            </div>
                            <div className='p-1' />
                            <div>
                              <div style={{ fontSize: 10, color: 'red' }}>
                                ATTENTION: Generated decision file will not be
                                visible to the broker
                              </div>
                              <Button
                                onClick={() =>
                                  generateCreditDoc(
                                    creditDecisionDoc,
                                    'credit_decision_doc',
                                  )
                                }
                              >
                                Generate Credit Decision Doc
                              </Button>
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-column'>
            {/* Attached Files */}
            <LeaseFiles
              data={data}
              setData={setData}
              isDisabled={needsInfoUpdate.successful}
              type='credit'
              IS_ADMIN={IS_ADMIN}
            />
            <LeaseFiles
              data={data}
              setData={setData}
              isDisabled={needsInfoUpdate.successful}
              type='creditLog'
              IS_ADMIN={IS_ADMIN}
            />
          </div>
        )}
      </div>
      <div className='lease-documentation-container'>
        {IS_ADMIN && (
          <div className='d-flex flex-column'>
            {/* Attached Files */}
            <LeaseFiles
              data={data}
              setData={setData}
              isDisabled={needsInfoUpdate.successful}
              type='credit'
              IS_ADMIN={IS_ADMIN}
            />
            <LeaseFiles
              data={data}
              setData={setData}
              isDisabled={needsInfoUpdate.successful}
              type='creditLog'
              IS_ADMIN={IS_ADMIN}
            />
          </div>
        )}
        <div className='d-flex flex-column'>
          {/* Admin Credit Approval */}
          {IS_ADMIN &&
            data.lease.status === LEASE_STATUS.LEASE_PENDING_CREDIT.status &&
            (didSubmitReview.credit ? (
              <p>You have already reviewed this credit application.</p>
            ) : (
              <LeaseAdminReview
                adminReview={adminCreditReview}
                setAdminReview={setAdminCreditReview}
                submitAdminReview={submitAdminReview}
                formType='Credit'
              />
            ))}

          {/* Lease Needs Info Feedback */}
          {data.lease.status ===
            LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status && (
            <LeaseNeedsInfo
              needsInfoUpdate={needsInfoUpdate}
              submitNeedsInfoUpdate={submitNeedsInfoUpdate}
              lease={data.lease}
              type='credit'
            />
          )}

          {/* Lease Denied */}
          {data.lease.status === LEASE_STATUS.LEASE_DENIED_CREDIT.status && (
            <LeaseDenied lease={data.lease} type='credit' />
          )}
        </div>
      </div>
    </>
  )
}

LeaseCreditForm.propTypes = {
  data: propTypes.object,
  user: propTypes.object,
  setData: propTypes.func,
  onLeaseInputChange: propTypes.func,
  IS_ADMIN: propTypes.bool,
  didSubmitReview: propTypes.object,
  setDidSubmitReview: propTypes.func,
}

export default LeaseCreditForm
