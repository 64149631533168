import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'
import { PDFDocument } from 'pdf-lib'

import { API, Storage, Lambda, Util } from '../amplify'
import { renderForm, SignEasy, parseLeaseFiles } from '../util'
import {
  splitMultiLineText,
  formatNumber,
  isMobile,
  isUUID,
  getFieldSetTextMaybe,
} from '../util/helpers'
import {
  createToast,
  createModal,
  formatDocusignAuditData,
} from '../util/reactHelpers'
import * as LEASE_STATUS from '../data/leaseStatus'
import contractPackage1 from '../assets/pdf/contract-package-1.pdf'
import contractPackage2 from '../assets/pdf/contract-package-2.pdf'
import contractPackage3 from '../assets/pdf/contract-package-3.pdf'
import contractPackage4 from '../assets/pdf/contract-package-4.pdf'
import insuranceDoc from '../assets/pdf/insurance-doc.pdf'

import Button from './Button'
import LeaseMainInfo from './LeaseMainInfo'
import LeaseFiles from './LeaseFiles'
import LeaseAdminReview from './LeaseAdminReview'
import LeaseNeedsInfo from './LeaseNeedsInfo'
import FileUpload from './FileUpload'
import { ESignatureStatus } from './ESignatureStatus'
import { buildAndSendEnvelope } from '../util/docusign/BuildAndSendEnvelope'
import { DOCUSIGN_ACTIONS } from '../util/docusign/DocusignActions'

const LeaseContractForm = ({
  data,
  setData,
  user,
  onLeaseInputChange,
  IS_ADMIN,
  didSubmitReview,
  setDidSubmitReview,
}) => {
  const DOCUMENT_SENT =
    (data.lease.status === LEASE_STATUS.LEASE_APPROVED_CONTRACT.status ||
      data.lease.status === LEASE_STATUS.LEASE_COMPLETE.status) &&
    data.lease.contract_document_id

  const DOCUMENT_ACTIONS =
    data.lease.status === LEASE_STATUS.LEASE_APPROVED_CONTRACT.status &&
    !data.lease.contract_document_id

  const ON_SIGNATURE_DECISION =
    !data.lease.contract_document_id &&
    !data.lease.assuming_contract_responsibility

  const IS_CONTRACT_EDITABLE = IS_ADMIN
    ? true
    : data.lease.status === LEASE_STATUS.LEASE_APPROVED_CREDIT.status ||
      data.lease.status === LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.status ||
      (data.lease.status === LEASE_STATUS.LEASE_APPROVED_CONTRACT.status &&
        ON_SIGNATURE_DECISION)

  const CONTRACT_NAME = 'ADD Capital Contract Package'

  const DOLLAR_FIELDS = [
    'rental_amount_1',
    'rental_amount_2',
    'pst_1',
    'pst_2',
    'gst_1',
    'gst_2',
    'total_1',
    'total_2',
    'vendor_invoice',
  ]

  // This is for the preview PDF doc (some fields we dont want to shrink)
  const USE_REGULAR_FONT_SIZE_FIELDS = [
    'commencement_date',
    'purchase_option_month',
    'purchase_option_price',
  ]

  useEffect(() => {
    if (DOCUMENT_SENT) {
      // is docusign document
      if (isUUID(data.lease.contract_document_id)) {
        Lambda.docusign({
          action: DOCUSIGN_ACTIONS.GET_ENVELOPE_STATUS,
          data: { envelopeId: data.lease.contract_document_id },
        })
          .then(({ data: documentInfo }) => {
            setDocumentInfo({ ...documentInfo, isDocusign: true })
          })
          .catch(error => {
            Util.LogError(
              'ERROR_FETCHING_DOCUSIGN_DOCUMENT',
              error,
              data.lease.contract_document_id,
            )
          })
      } else {
        SignEasy.getPendingDocumentStatus(data.lease.contract_document_id)
          .then(({ data: documentInfo }) => {
            setDocumentInfo({ ...documentInfo, isDocusign: false })
          })
          .catch(error => {
            Util.LogError(
              'ERROR_FETCHING_SIGN_EASY_DOCUMENT',
              error,
              data.lease.contract_document_id,
            )
          })
      }
    }
  }, [data.lease.contract_document_id])

  const [documentInfo, setDocumentInfo] = useState(null)
  const [contractInfo, setContractInfo] = useState({
    loading: false,
    response: null,
    successful: false,
  })
  const [needsInfoUpdate, setNeedsInfoUpdate] = useState({
    loading: false,
    response: null,
    successful: false,
  })
  const [adminContractReview, setAdminContractReview] = useState({
    status: null,
    feedback: null,
    loading: false,
    response: null,
    successful: false,
  })
  const [signReminderData, setSignReminderData] = useState({
    loading: false,
    response: null,
  })
  const [updateContractLoading, setUpdateContractLoading] = useState(false)
  const [documentActions, setDocumentActions] = useState({
    loading: false,
    successful: false,
  })
  const [saveAndPreviewLoading, setSaveAndPreviewLoading] = useState(false)
  const [contractComplete, setContractComplete] = useState({
    loading: false,
    successful: false,
  })

  const [modalData, setModalData] = useState({
    isOpen: false,
    title: '',
    description: '',
    submitText: '',
    submitHandler: () => {},
  })

  const IS_MOBILE = isMobile('sm')

  useEffect(() => {
    // add completed e signature document to S3
    if (documentInfo?.isDocusign) {
      if (
        documentInfo?.status === 'completed' &&
        data.lease.contract_document_id
      ) {
        // only upload the completed contract if it is not already there
        if (
          !data.files.generatedContract.filter(file =>
            file.key.includes(`${CONTRACT_NAME}.pdf`),
          ).length
        ) {
          uploadCompletedDocusignDocument()
        }
      }
    } else {
      if (
        documentInfo?.status === 'complete' &&
        data.lease.contract_document_id
      ) {
        // only upload the completed contract if it is not already there
        if (
          !data.files.generatedContract.filter(file =>
            file.key.includes(`${CONTRACT_NAME}.pdf`),
          ).length
        ) {
          uploadCompletedSignEasyDocument()
        }
      }
    }
  }, [documentInfo])

  const submitAdminReview = async e => {
    e.preventDefault()

    let status
    let notificationStatus

    switch (adminContractReview.status) {
      case LEASE_STATUS.APPROVE_CONTRACT:
        status = LEASE_STATUS.LEASE_APPROVED_CONTRACT.status
        notificationStatus = 'has had the contract approved by ADD Capital'
        break

      case LEASE_STATUS.NEEDS_INFO_CONTRACT:
        status = LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.status
        notificationStatus = 'needs more contract information'
        break

      default:
        break
    }

    try {
      setAdminContractReview({
        ...adminContractReview,
        loading: true,
      })

      await API.updateLease({
        id: data.lease.id,
        status,
        admin_contract_feedback: adminContractReview.feedback,
      })

      // send notification to broker
      await Lambda.sendEmail({
        IS_NOTIFICATION: true,
        TO: data.broker.email,
        FROM: 'CONTRACT',
        name: data.broker.name,
        clientName: data.lease.main_lessee_name,
        leaseName: data.lease.main_lessee_name,
        leaseStatus: notificationStatus,
        leaseNextSteps: 'View the contract application to see any feedback',
        leaseID: data.lease.id,
      })

      // so admin cannot flip tabs and re-review again
      setDidSubmitReview({
        credit: false,
        contract: true,
      })
      const msg = 'Successfully Reviewed Contract Application'
      createToast(msg)
      setAdminContractReview({
        ...adminContractReview,
        loading: false,
        response: msg,
        successful: true,
      })
    } catch (error) {
      Util.LogError('ERROR_ADMIN_CONTRACT_RESPONSE', error, {
        user: user.attributes,
        data,
      })
      const msg =
        'An unknown error has occurred when submitting the response. Please try again.'
      createToast(msg, 'error')
      setAdminContractReview({
        ...adminContractReview,
        loading: false,
        response: msg,
      })
    }
  }

  const submitNeedsInfoUpdate = async () => {
    setNeedsInfoUpdate({
      loading: true,
      response: null,
    })

    try {
      await API.updateLease({
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
        status: LEASE_STATUS.LEASE_PENDING_CONTRACT.status,
      })

      // send notification to ADD about contract resubmission
      await Lambda.sendEmail({
        IS_NOTIFICATION: true,
        TO: 'CONTRACT',
        FROM: 'CONTRACT',
        name: 'ADD Capital',
        clientName: data.lease.main_lessee_name,
        leaseName: data.lease.main_lessee_name,
        leaseStatus:
          'has been re-submitted for contract approval by the broker',
        leaseNextSteps:
          'View the contract application to provide feedback to the broker',
        leaseID: data.lease.id,
      })

      const msg = 'Successfully Re-submitted Contract Info'
      createToast(msg)
      setNeedsInfoUpdate({
        loading: false,
        response: msg,
        successful: true,
      })
    } catch (error) {
      Util.LogError('ERROR_RESUBMITTING_LEASE', error, {
        user: user.attributes,
        data,
      })
      const msg =
        'An unknown error has occurred when re-submitting the contract info. Please try again.'
      createToast(msg, 'error')
      setNeedsInfoUpdate({
        loading: false,
        response: msg,
      })
    }
  }

  const submitContract = async approvalType => {
    setContractInfo({
      loading: true,
      response: null,
    })

    try {
      let status
      let msg
      if (approvalType === 'WITH') {
        status = LEASE_STATUS.LEASE_PENDING_CONTRACT.status
        msg = 'Successfully Submitted Contract Info For Review'
      } else if (approvalType === 'WITHOUT') {
        status = LEASE_STATUS.LEASE_APPROVED_CONTRACT.status
        msg = 'Successfully Submitted Contract Info Without Approval'
      }

      // close confirmation modal
      setModalData({
        isOpen: false,
        title: '',
        description: '',
        submitText: '',
        submitHandler: () => {},
      })

      const updatedLease = await API.updateLease({
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
        status,
      })

      // set new lease data in local state to move to next step
      setData({
        ...data,
        lease: updatedLease.data.updateLease,
      })

      // send notification after update succeeds
      // broker wants approval from ADD
      if (approvalType === 'WITH') {
        await Lambda.sendEmail({
          IS_NOTIFICATION: true,
          TO: 'CONTRACT',
          FROM: 'CONTRACT',
          name: 'ADD Capital',
          clientName: data.lease.main_lessee_name,
          leaseName: data.lease.main_lessee_name,
          leaseStatus: 'has been submitted for contract approval by the broker',
          leaseNextSteps:
            'View the contract application to provide feedback to the broker',
          leaseID: data.lease.id,
        })
      }

      createToast(msg)
      setContractInfo({
        loading: false,
        response: msg,
        successful: true,
      })
    } catch (error) {
      Util.LogError('ERROR_SUBMITTING_CONTRACT', error, {
        user: user.attributes,
        data,
      })
      createToast('Error submitting contract info. Please try again.', 'error')
      setContractInfo({
        loading: false,
        successful: false,
      })
    }
  }

  const updateContract = async () => {
    setUpdateContractLoading(true)

    try {
      await API.updateLease({
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
      })

      const msg = 'Successfully Saved Contract Information'
      createToast(msg)
      setUpdateContractLoading(false)
    } catch (error) {
      Util.LogError('ERROR_UPDATING_CONTRACT', error, {
        user: user.attributes,
        data,
      })
      createToast('Error updating contract info. Please try again.', 'error')
      setUpdateContractLoading(false)
    }
  }

  const completeLease = async () => {
    const status = LEASE_STATUS.LEASE_COMPLETE.status
    setContractComplete({
      loading: true,
    })
    try {
      await API.updateLease({
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
        status,
      })

      setContractComplete({
        loading: false,
        successful: true,
      })
      createToast(
        "Success! Lease has been moved to the 'Lease Complete' category",
      )
    } catch (error) {
      Util.LogError('ERROR_UPDATING_STATUS_TO_LEASE_COMPLETE', error, {
        user: user.attributes,
        data,
      })
      setContractComplete({
        loading: false,
        successful: false,
      })
      createToast(
        'Error updating lease status to complete. Please try again.',
        'error',
      )
    }
  }

  const renderContractMessage = () => {
    let message

    switch (data.lease.status) {
      case LEASE_STATUS.LEASE_APPROVED_CREDIT.status:
        message = (
          <h5>Terms are subject to the credit conditions indicated below:</h5>
        )
        break

      case LEASE_STATUS.LEASE_APPROVED_CONTRACT.status:
        message = <h5>Your contract information has been approved!</h5>
        break

      case LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.status:
        message = (
          <h5>
            After submitting the contract information for review, we have
            determined there is still some information needed. Please see the
            feedback.
          </h5>
        )
        break

      case LEASE_STATUS.LEASE_PENDING_CONTRACT.status:
        message = (
          <h5>
            Your contract submission is being reviewed. Thank you for your
            patience.
          </h5>
        )
        break

      case LEASE_STATUS.LEASE_COMPLETE.status:
        message = (
          <h5>
            This lease has been marked as complete by an admin. You may not make
            any further changes.
          </h5>
        )
        break

      default:
        message = (
          <h5>
            The submitted application is not yet in the Contract Review Stage.
            Please check back soon.
          </h5>
        )
        break
    }

    return message
  }

  const generateESignatureRequest = async () => {
    setDocumentActions({
      loading: true,
      successful: false,
    })

    try {
      // close confirmation modal
      setModalData({
        isOpen: false,
        title: '',
        description: '',
        submitText: '',
        submitHandler: () => {},
      })

      // remove any old ADD contracts before requesting sign easy
      await Storage.deleteDocument(
        `leases/${data.lease.id}/generatedContract/${CONTRACT_NAME}.pdf`,
      )

      const envelopeData = await createDocusignEnvelope()
      if (!envelopeData?.envelopeId) return

      const updatedLease = await API.updateLease({
        id: data.lease.id,
        contract_document_id: envelopeData.envelopeId,
      })

      // set new lease data in local state to move to next step reactively
      setData({
        ...data,
        lease: updatedLease.data.updateLease,
        files: {
          ...data.files,
          generatedContract: data.files.generatedContract.filter(
            file => !file.key.includes(CONTRACT_NAME),
          ),
        },
      })

      createToast('Successfully sent contract out for signatures.')
      setDocumentActions({
        loading: false,
        successful: true,
      })
    } catch (error) {
      createToast(
        'Something went wrong while sending the contract. Please try again.',
        'error',
      )
      setDocumentActions({
        loading: false,
        successful: false,
      })
    }
  }

  const noSignatureContractHandler = async () => {
    setDocumentActions({
      loading: true,
      successful: false,
    })

    try {
      // close confirmation modal
      setModalData({
        isOpen: false,
        title: '',
        description: '',
        submitText: '',
        submitHandler: () => {},
      })

      const numCoLessees = Object.keys(data.lease.co_lessees).length / 2
      let contractBlob
      if (numCoLessees === 0) {
        contractBlob = await generateNoSignatureContract(contractPackage1)
      } else if (numCoLessees === 1) {
        contractBlob = await generateNoSignatureContract(contractPackage2)
      } else if (numCoLessees === 2) {
        contractBlob = await generateNoSignatureContract(contractPackage3)
      } else {
        contractBlob = await generateNoSignatureContract(contractPackage4)
      }

      // Upload file to storage then set updated files locally
      await Storage.uploadDocument(
        `leases/${data.lease.id}/generatedContract/${CONTRACT_NAME}.pdf`,
        contractBlob,
      )
      const updatedFiles = await Storage.listDocuments(
        `leases/${data.lease.id}`,
      )
      const sortedFiles = parseLeaseFiles(updatedFiles)

      // update lease to indicate broker is handling the contract, not sign easy
      const updatedLease = await API.updateLease({
        id: data.lease.id,
        assuming_contract_responsibility: true,
      })
      // set new data in local state to move to next step reactively
      setData({
        ...data,
        lease: updatedLease.data.updateLease,
        files: sortedFiles,
      })

      createToast(
        'Successfully Generated Lease Contract. You may now view/download the contract.',
      )
      setDocumentActions({
        loading: false,
        successful: true,
      })
    } catch (error) {
      createToast(
        'Error creating the lease contract. Please try again.',
        'error',
      )
      Util.LogError('ERROR_GENERATING_NO_SIGNATURE_LEASE_CONTRACT', error, {
        user: user.attributes,
        data,
      })
      setDocumentActions({
        loading: false,
        successful: false,
      })
    }
  }

  /**
   *
   * @return {FileBlob} Returns No-Sig Generated Lease Contract file blob
   */
  const generateNoSignatureContract = async (
    blankContractDoc,
    isInsuranceDoc,
  ) => {
    const blankPdfBytes = await fetch(blankContractDoc).then(res =>
      res.arrayBuffer(),
    )
    const pdfDoc = await PDFDocument.load(blankPdfBytes)

    const form = pdfDoc.getForm()
    const fields = form.getFields()
    const shouldUseDocusignFonts = !isInsuranceDoc

    // extend form class
    form.getFieldSetTextMaybe = getFieldSetTextMaybe

    // set all fields directly from lease data
    fields.forEach(field => {
      const name = field.getName()
      if (DOLLAR_FIELDS.includes(name)) {
        field.setText(
          String(
            !data.lease[name] || data.lease[name] === 'false'
              ? ''
              : String(data.lease[name]).toLowerCase() === 'n/a'
                ? 'N/A'
                : `$${formatNumber(data.lease[name])}`,
          ),
        )
      } else {
        field.setText(
          String(
            !data.lease[name] || data.lease[name] === 'false'
              ? ''
              : String(data.lease[name]).toLowerCase() === 'n/a'
                ? 'N/A'
                : data.lease[name],
          ),
        )
      }

      if (
        shouldUseDocusignFonts &&
        !USE_REGULAR_FONT_SIZE_FIELDS.includes(name)
      ) {
        field.setFontSize(8)
      }
    })

    // set co-lessees
    Object.keys(data.lease.co_lessees).forEach(colessee => {
      if (colessee.includes('name')) {
        form.getFieldSetTextMaybe(
          colessee,
          data.lease.co_lessees[colessee],
          shouldUseDocusignFonts,
        )
      }
    })

    // set co-lessees combined for purchase option and insurance docs
    let coLesseesCombined = data.lease.main_lessee_name
    Object.keys(data.lease.co_lessees).forEach(colessee => {
      if (colessee.includes('name')) {
        coLesseesCombined += ' & ' + data.lease.co_lessees[colessee]
      }
    })

    form.getFieldSetTextMaybe(
      'co_lessees_combined',
      coLesseesCombined,
      shouldUseDocusignFonts,
    )
    if (isInsuranceDoc) {
      form.getFieldMaybe('co_lessees_combined').setFontSize(8)
    }

    // set assets and serial numbers
    const assets = splitMultiLineText(data.lease.asset_description)
    const serialNumbers = splitMultiLineText(data.lease.serial_number, true)

    form.getFieldSetTextMaybe(
      'asset_description_1',
      assets[0],
      shouldUseDocusignFonts,
    )
    form.getFieldSetTextMaybe(
      'asset_description_2',
      assets[1],
      shouldUseDocusignFonts,
    )
    form.getFieldSetTextMaybe(
      'asset_description_3',
      assets[2],
      shouldUseDocusignFonts,
    )
    form.getFieldSetTextMaybe(
      'serial_number_1',
      serialNumbers[0],
      shouldUseDocusignFonts,
    )
    form.getFieldSetTextMaybe(
      'serial_number_2',
      serialNumbers[1],
      shouldUseDocusignFonts,
    )
    form.getFieldSetTextMaybe(
      'serial_number_3',
      serialNumbers[2],
      shouldUseDocusignFonts,
    )

    // set purchase option and insurance doc asset descriptions
    form.getFieldSetTextMaybe(
      'asset_serial_combined_1',
      `${assets[0] || ''} ${serialNumbers[0] || ''}`,
      shouldUseDocusignFonts,
    )
    form.getFieldSetTextMaybe(
      'asset_serial_combined_2',
      `${assets[1] || ''} ${serialNumbers[1] || ''}`,
      shouldUseDocusignFonts,
    )
    form.getFieldSetTextMaybe(
      'asset_serial_combined_3',
      `${assets[2] || ''} ${serialNumbers[2] || ''}`,
      shouldUseDocusignFonts,
    )

    // set monthly field
    form.getFieldSetTextMaybe('monthly', 'X', shouldUseDocusignFonts)

    // set 'other' fields as NA every time
    form.getFieldSetTextMaybe('other_1', 'N/A', shouldUseDocusignFonts)
    form.getFieldSetTextMaybe('other_2', 'N/A', shouldUseDocusignFonts)

    // set purchase price with tax
    form.getFieldSetTextMaybe(
      'purchase_option_price',
      `$${formatNumber(data.lease.purchase_price) || ''} + taxes`,
    )

    // set insurance value field
    const insuranceValue =
      Number(data.lease.number_of_payments_1) *
        Number(data.lease.rental_amount_1) +
      Number(data.lease.number_of_payments_2) *
        Number(data.lease.rental_amount_2)

    form.getFieldSetTextMaybe(
      'insurance_value',
      String(formatNumber(insuranceValue)),
      shouldUseDocusignFonts,
    )

    form.flatten()

    // update titles
    if (isInsuranceDoc) {
      pdfDoc.setTitle('Insurance Doc')
    } else {
      pdfDoc.setTitle('ADD Capital Contract Package')
    }

    const pdfBytes = await pdfDoc.save()
    return new Blob([pdfBytes], { type: 'application/pdf' })
  }

  const createDocusignEnvelope = async () => {
    try {
      const envelopeResult = await buildAndSendEnvelope(data.lease)
      return envelopeResult
    } catch (error) {
      Util.LogError('ERROR_CREATING_DOCUSIGN_DOCUMENT', error, data.lease)
      createToast('Error Generating E-Document. Please try again.', 'error')
    }
  }

  const uploadCompletedDocusignDocument = async () => {
    try {
      const { data: pdf } = await Lambda.docusign({
        action: DOCUSIGN_ACTIONS.DOWNLOAD_ENVELOPE_DOCUMENTS,
        data: {
          envelopeId: data.lease.contract_document_id,
          docType: 'combined', // 'combined' for contract, 'certificate' for cert
        },
      })

      const fileBlob = new Blob([new Uint8Array(pdf.data).buffer], {
        type: 'application/pdf',
      })

      // Upload file to storage then set updated files locally
      await Storage.uploadDocument(
        `leases/${data.lease.id}/generatedContract/${CONTRACT_NAME}.pdf`,
        fileBlob,
      )
      const updatedFiles = await Storage.listDocuments(
        `leases/${data.lease.id}`,
      )
      setData({
        ...data,
        files: parseLeaseFiles(updatedFiles),
      })
    } catch (error) {
      Util.LogError('ERROR_FETCHING/DOWNLOADING_SIGNED_E-DOCUMENT', error, {
        docInfo: documentInfo,
        data,
      })
      createToast('Error fetching signed document. Please try again.', 'error')
    }
  }

  const uploadCompletedSignEasyDocument = async () => {
    try {
      const signedFile = (
        await SignEasy.getSignedDocumentStatus(documentInfo.id)
      ).data
      const rawFile = (await SignEasy.downloadDocument(signedFile.id)).data
      const fileBlob = new Blob([rawFile], { type: 'application/pdf' })

      // Upload file to storage then set updated files locally
      await Storage.uploadDocument(
        `leases/${data.lease.id}/generatedContract/${CONTRACT_NAME}.pdf`,
        fileBlob,
      )
      const updatedFiles = await Storage.listDocuments(
        `leases/${data.lease.id}`,
      )
      setData({
        ...data,
        files: parseLeaseFiles(updatedFiles),
      })
    } catch (error) {
      Util.LogError('ERROR_FETCHING/DOWNLOADING_SIGNED_E-DOCUMENT', error, {
        docInfo: documentInfo,
        data,
      })
      createToast('Error fetching signed document. Please try again.', 'error')
    }
  }

  const viewContractAuditTrail = async () => {
    try {
      if (documentInfo.isDocusign) {
        const { data: auditEvents } = await Lambda.docusign({
          action: DOCUSIGN_ACTIONS.LIST_AUDIT_EVENTS,
          data: { envelopeId: data.lease.contract_document_id },
        })

        setModalData({
          isOpen: true,
          title: 'Audit Events for E-Signature Document',
          description: formatDocusignAuditData(auditEvents),
          submitText: null,
          contentLabel: 'E-Signature Document Audit Events',
          noMarginTop: true,
          isNode: true,
        })
      } else {
        const signedFile = (
          await SignEasy.getSignedDocumentStatus(documentInfo.id)
        ).data
        const rawFile = (await SignEasy.downloadAuditTrail(signedFile.id)).data
        const fileBlob = new Blob([rawFile], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(fileBlob)
        window.open(fileURL, '_blank')
      }
    } catch (error) {
      Util.LogError(
        'ERROR_FETCHING/DOWNLOADING_SIGNED_E-DOCUMENT_AUDIT_TRAIL',
        error,
        documentInfo,
      )
      createToast(
        'Error Fetching Signed Document Audit Trail. Please try again.',
        'error',
      )
    }
  }

  const viewContractCertificate = async () => {
    try {
      const { data: pdf } = await Lambda.docusign({
        action: DOCUSIGN_ACTIONS.DOWNLOAD_ENVELOPE_DOCUMENTS,
        data: {
          envelopeId: data.lease.contract_document_id,
          docType: 'certificate',
        },
      })
      const fileBlob = new Blob([new Uint8Array(pdf.data).buffer], {
        type: 'application/pdf',
      })
      const fileURL = URL.createObjectURL(fileBlob)
      window.open(fileURL, '_blank')
    } catch (error) {
      Util.LogError(
        'ERROR_FETCHING/DOWNLOADING_SIGNED_E-DOCUMENT_CERT',
        error,
        documentInfo,
      )
      createToast(
        'Error Fetching Signed Document Certificate. Please try again.',
        'error',
      )
    }
  }

  const remindLessees = async () => {
    try {
      setSignReminderData({ ...signReminderData, loading: true })
      await SignEasy.remindSigners(documentInfo.id)
      const msg = 'Successfully reminded Lessees.'
      createToast(msg)
      setSignReminderData({
        ...signReminderData,
        loading: false,
        response: msg,
      })
    } catch (error) {
      const msg = 'An unknown error occurred. Please try again.'
      createToast(error.response?.data?.message || msg, 'error')
      setSignReminderData({
        ...signReminderData,
        loading: false,
        response: msg,
      })
      Util.LogError('ERROR_SENDING_E-DOCUMENT_REMINDER', error, documentInfo)
    }
  }

  const saveAndGenerateContract = async () => {
    setSaveAndPreviewLoading(true)

    try {
      await API.updateLease({
        ...data.lease,
        co_lessees: JSON.stringify(data.lease?.co_lessees || {}),
      })

      const numCoLessees = Object.keys(data.lease.co_lessees).length / 2
      let contractBlob
      if (numCoLessees === 0) {
        contractBlob = await generateNoSignatureContract(contractPackage1)
      } else if (numCoLessees === 1) {
        contractBlob = await generateNoSignatureContract(contractPackage2)
      } else if (numCoLessees === 2) {
        contractBlob = await generateNoSignatureContract(contractPackage3)
      } else {
        contractBlob = await generateNoSignatureContract(contractPackage4)
      }
      const insuranceDocBlob = await generateNoSignatureContract(
        insuranceDoc,
        true,
      )

      // Upload contract package blob to storage
      await Storage.uploadDocument(
        `leases/${data.lease.id}/generatedContract/${CONTRACT_NAME}.pdf`,
        contractBlob,
      )
      // Upload insurance doc blob to storage
      await Storage.uploadDocument(
        `leases/${data.lease.id}/generatedContract/Insurance Doc.pdf`,
        insuranceDocBlob,
      )

      // set updated files locally
      const updatedFiles = await Storage.listDocuments(
        `leases/${data.lease.id}`,
      )
      const sortedFiles = parseLeaseFiles(updatedFiles)

      // add updated file to contract files
      setData({
        ...data,
        files: sortedFiles,
      })

      const msg =
        'Successfully Updated Lease Contract Documents. You may view the updated document package under Attached Generated Contract Files.'
      createToast(msg)
      setSaveAndPreviewLoading(false)
    } catch (error) {
      createToast(
        'Unable to save and update the lease contract documents. Please try again',
        'error',
      )
      Util.LogError('ERROR_SAVING_AND_GENERATING_CONTRACT_BROKER', error, {
        user: user.attributes,
        data,
      })
      setSaveAndPreviewLoading(false)
    }
  }

  const validateRequiredFields = fields => {
    let error = false
    // check if required fields are present
    fields.forEach(field => {
      if (!field) {
        // todo get name from field if more are needed
        createToast('Commencement Date is a required field!', 'error')
        error = true
      }
    })
    if (error) return true

    // make sure main lessee email is filled out
    if (!data.lease.main_lessee_email) {
      createToast('The main lessee email is a required field!', 'error')
      return true
    }

    // make sure all co-lessee emails are filled out
    Object.keys(data.lease.co_lessees).forEach(key => {
      const val = data.lease.co_lessees[key]
      if (!error && key.includes('co_lessee_email') && !val) {
        createToast(
          `Co-Lessee ${key.split('_')[3]} Email is a required field!`,
          'error',
        )
        error = true
      }
    })
    if (error) return true

    return error
  }

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '50px' }}>
        {!IS_ADMIN && renderContractMessage()}
      </div>

      <div className='lease-documentation-container'>
        {/* Main Lease Info */}
        <div>
          <LeaseMainInfo data={data} />
          <>
            <div>IPC payable to ADD submitted along with documentation</div>
            <div>All documents to comply with PIPEDA legislation</div>
            <div>
              Suppliers&apos; invoices made out to ADD and current dated
            </div>
            <div>Amounts over $5,000 to be certified</div>
            <div>PAPP mandatory on all leases</div>
          </>
        </div>

        {/* Document Info */}
        <div className='info-block'>
          <b>DOCUMENTATION REQUIRED:</b>
          <ul>
            <li>Lease Contract and related documents as provided</li>
            <li>
              Insurance showing ADD as loss payee and additionally insured
            </li>
            <li>Copy of Incorporation Papers or Profile Report</li>
            <li>Individual’s ID: current DL</li>
            <li>
              Picture of Truck or Trailer, Odometer, and VIN Plate required on
              all Private Sale transactions involving a motor vehicle
            </li>
          </ul>
        </div>
      </div>

      {![
        LEASE_STATUS.LEASE_DENIED_CREDIT.status,
        LEASE_STATUS.LEASE_PENDING_CREDIT.status,
        LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status,
      ].includes(data.lease.status) && (
        <>
          <div className='d-flex justify-content-between'>
            <div className='w-100'>
              {/* Lease Contract Form */}
              <div
                className='dropdown-form'
                style={{ width: '100%', marginBottom: 50 }}
              >
                <div className='dropdown-form-title'>Contract Information</div>
                <div className='dropdown-form-content'>
                  {renderForm({
                    schema: 'contract',
                    labelType: 'view',
                    IS_ADMIN,
                    IS_MOBILE,
                    inputProps: {
                      disabled:
                        needsInfoUpdate.successful || !IS_CONTRACT_EDITABLE,
                      onChange: onLeaseInputChange,
                      data: data.lease,
                    },
                  })}

                  {/* Admin and Broker Update Contract Button */}
                  <div className='text-center'>
                    {updateContractLoading || saveAndPreviewLoading ? (
                      <ClipLoader loading size='30px' color='#3b9453' />
                    ) : (
                      <div className='d-flex flex-column align-items-center'>
                        {!data.lease.contract_document_id && (
                          <>
                            <div style={{ fontSize: 10, color: 'red' }}>
                              ATTENTION: You can edit, generate, and preview
                              documents as many times as you wish.
                              <br />
                              Generated documents from this form will appear at
                              the bottom of this page for preview.
                            </div>
                            <Button onClick={saveAndGenerateContract}>
                              Save and Review Contract Documents
                            </Button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='lease-contract-bottom-container'>
            <>
              {/* Contract Document Status */}
              {DOCUMENT_SENT && (
                <div className='signature-status-container'>
                  <b style={{ textDecoration: 'underline' }}>
                    Contract Signature Status
                  </b>
                  {documentInfo ? (
                    <ESignatureStatus
                      documentInfo={documentInfo}
                      viewContractAuditTrail={viewContractAuditTrail}
                      viewContractCertificate={viewContractCertificate}
                      signReminderData={signReminderData}
                      remindLessees={remindLessees}
                      lease={data.lease}
                    />
                  ) : (
                    <div
                      className='centeredContentPage'
                      style={{ minHeight: 30, paddingTop: 30 }}
                    >
                      <ClipLoader loading size='30px' color='#3b9453' />
                    </div>
                  )}
                </div>
              )}
            </>

            {/* Admin Contract Approval */}
            {IS_ADMIN &&
              data.lease.status ===
                LEASE_STATUS.LEASE_PENDING_CONTRACT.status &&
              (didSubmitReview.contract ? (
                <p>You have already reviewed this contract application.</p>
              ) : (
                <LeaseAdminReview
                  adminReview={adminContractReview}
                  setAdminReview={setAdminContractReview}
                  submitAdminReview={submitAdminReview}
                  formType='Contract'
                />
              ))}

            {/* Send out E-signature or generate lease contract section */}
            {DOCUMENT_ACTIONS ? (
              documentActions.loading ? (
                <div
                  className='centeredContentPage'
                  style={{ minHeight: 30, paddingTop: 30 }}
                >
                  <ClipLoader loading size='60px' color='#3b9453' />
                </div>
              ) : (
                !data.lease.assuming_contract_responsibility && (
                  <div className='d-flex flex-column align-items-center mb-2'>
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: 10,
                        color: 'red',
                      }}
                    >
                      ATTENTION: Once an option is selected below, you will not
                      be able to edit the contract details above anymore.
                      <br />
                      You will not be able to undo this action. Make sure the{' '}
                      <i>{CONTRACT_NAME} </i> is up to date and accurate.
                    </div>
                    <br />
                    <div>
                      <Button
                        onClick={() =>
                          setModalData({
                            isOpen: true,
                            title: 'Confirm E-Signature Request',
                            description: `Confirm that you wish to automatically send e-signature requests for the ${CONTRACT_NAME} document to all listed lessees via e-mail. After sending, you will be able to view the status of the signatures. Make sure the contract is up to date with the information you entered before you proceed.`,
                            submitText: 'Send E-Signature Contract Request',
                            submitHandler: generateESignatureRequest,
                          })
                        }
                        disabled={documentActions.successful}
                      >
                        Send E-Signature Contract Request
                      </Button>
                    </div>
                    <div className='m-1' style={{ textAlign: 'center' }}>
                      <i>Or</i>
                    </div>
                    <div>
                      <Button
                        onClick={() =>
                          setModalData({
                            isOpen: true,
                            title: 'Confirm Document Generation',
                            description: `Confirm that you wish to generate an ${CONTRACT_NAME} document with all information populated except the lessee signatures. You may print this out or email this as needed.`,
                            submitText:
                              'Generate Contract without E-Signatures',
                            submitHandler: noSignatureContractHandler,
                          })
                        }
                        disabled={documentActions.successful}
                      >
                        Generate Contract without E-Signatures
                      </Button>
                    </div>
                  </div>
                )
              )
            ) : null}

            {/* Upload Updated ADD contract section if handled on own */}
            {data.lease.assuming_contract_responsibility && (
              <div className='info-block-without-margin'>
                <b>Upload Updated ADD Capital Contract Package</b>
                <ul>
                  <li>
                    Once the contract package has been signed and finalized,
                    upload it here
                  </li>
                  <li>
                    Note this will overwrite the contract package on the right,
                    so make sure it is correct
                  </li>
                </ul>
                <FileUpload
                  leaseMetaData={{ id: data.lease.id }}
                  type='generatedContract'
                  constantFileName={`${CONTRACT_NAME}.pdf`}
                  fileActionHook={async () => {
                    const updatedFiles = await Storage.listDocuments(
                      `leases/${data.lease.id}`,
                    )
                    setData({
                      ...data,
                      files: parseLeaseFiles(updatedFiles),
                    })
                  }}
                />
              </div>
            )}

            {/* Attached Files */}
            <div className='d-flex flex-column'>
              <LeaseFiles
                data={data}
                setData={setData}
                isDisabled={
                  needsInfoUpdate.successful || contractInfo.successful
                }
                type='contract'
                IS_ADMIN={IS_ADMIN}
              />
              {saveAndPreviewLoading ? (
                <div className='w-100 d-flex justify-content-center'>
                  <ClipLoader loading size='30px' color='#3b9453' />
                </div>
              ) : (
                <LeaseFiles
                  data={data}
                  setData={setData}
                  isDisabled
                  type='generatedContract'
                />
              )}
            </div>
            {/* Contract Needs Info Feedback */}
            {data.lease.status ===
              LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.status && (
              <LeaseNeedsInfo
                needsInfoUpdate={needsInfoUpdate}
                submitNeedsInfoUpdate={submitNeedsInfoUpdate}
                lease={data.lease}
                type='contract'
              />
            )}

            {/* Submit Lease Contract Button */}
            {contractInfo.loading ? (
              <div
                className='centeredContentPage'
                style={{ minHeight: 30, paddingTop: 30 }}
              >
                <ClipLoader loading size='60px' color='#3b9453' />
              </div>
            ) : (
              data.lease.status === LEASE_STATUS.LEASE_APPROVED_CREDIT.status &&
              !IS_ADMIN && (
                <div className='my-3 d-flex flex-column align-items-center'>
                  <div>
                    <Button
                      onClick={() => {
                        if (
                          validateRequiredFields([data.lease.commencement_date])
                        ) {
                          return
                        }

                        setModalData({
                          isOpen: true,
                          title: 'Confirm Approval Intent',
                          description:
                            'Confirm that you wish to submit the contract details and wait for an approval from ADD Capital.',
                          submitText: 'Submit Contract for Approval',
                          submitHandler: () => submitContract('WITH'),
                        })
                      }}
                      disabled={contractInfo.successful}
                    >
                      Submit Contract Details For Approval
                    </Button>
                  </div>

                  <div className='m-1'>
                    {' '}
                    <i>Or</i>{' '}
                  </div>

                  <div>
                    <Button
                      onClick={() => {
                        if (
                          validateRequiredFields([data.lease.commencement_date])
                        ) {
                          return
                        }

                        setModalData({
                          isOpen: true,
                          title: 'Confirm Approval Intent',
                          description:
                            'Confirm that you wish to continue the lease contract without approval from ADD Capital. Note that you assume full responsibility for the validity of this contract.',
                          submitText: 'Continue Contract Without Approval',
                          submitHandler: () => submitContract('WITHOUT'),
                        })
                      }}
                      disabled={contractInfo.successful}
                    >
                      Continue Lease Contract Without Approval
                    </Button>
                  </div>
                </div>
              )
            )}
          </div>
          {/* Complete Lease button for admins. Kind of brutal ternary but basically only
           show button if e-sig docs have been sent or contract has been generated */}
          <div className='d-flex justify-content-end'>
            {contractComplete.loading ? (
              <ClipLoader loading size='60px' color='#3b9453' />
            ) : (
              (data.lease.assuming_contract_responsibility || DOCUMENT_SENT) &&
              IS_ADMIN &&
              data.lease.status ===
                LEASE_STATUS.LEASE_APPROVED_CONTRACT.status &&
              !contractComplete.successful && (
                <div
                  className='d-flex flex-row flex-wrap justify-content-center'
                  style={{ width: '33%' }}
                >
                  <div style={{ fontSize: 10, color: 'red' }}>
                    ATTENTION: You will no longer be able to make changes to
                    this lease
                  </div>
                  <Button onClick={completeLease}>Complete Lease</Button>
                </div>
              )
            )}
          </div>
        </>
      )}
      {createModal(modalData, setModalData)}
    </>
  )
}

LeaseContractForm.propTypes = {
  data: propTypes.object,
  setData: propTypes.func,
  onLeaseInputChange: propTypes.func,
  IS_ADMIN: propTypes.bool,
  didSubmitReview: propTypes.object,
  setDidSubmitReview: propTypes.func,
}

export default LeaseContractForm
